@import '../../styles/customMediaQueries.css';

.listingPage {
  background-color: #000;
}

.root {}

.pageRoot {
  padding-bottom: 0px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }

  @media (max-width: 1023px) {
    overflow-x: hidden;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  background-color: #000;
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  color: var(--White, #fff);
  font-family: 'Sofia Pro';
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  /* 100% */
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    text-align: center;
  }
}

.sectionHeadingWithExtraMargin {}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  margin-top: 42px;
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 0px;
    scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
  }

  @media (--viewportLarge) {
    padding: 0px 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.sectionAuthor {
  @media (max-width: 767px) {
    padding-bottom: 58px;
    border-bottom: 1px solid #fff;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.previewContainer {
  position: relative;
  max-width: 100vw;
}

.episodesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
  gap: 35px 28px;
  margin-top: 34px;
  cursor: pointer;
  /* margin-bottom: 71px;
  padding-bottom: 134px;
  border-bottom: 1px solid #fff; */
}

.episodeCard {
  background-color: #171819;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  text-align: left;
  padding: 33px 36px 45px;

  @media (max-width: 767px) {
    padding: 33px 20px 45px;
  }

  & .episodeDetails {
    margin-top: 24px;
  }
}

.episodeThumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
  max-height: 197px;
  object-fit: cover;
}

.episodeDetails {
  margin-top: 10px;
}

.episodeTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 125% */
  letter-spacing: -0.348px;
  color: #fff;
  margin: 7px 0;
  padding: 0;
}

.episodeDescription {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0px 0 20px;
  padding: 0;
  word-break: break-word;
}

.episodeDuration {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: 0.3px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.playButton {
  background-color: #f5d90a;
  border: none;
  padding: 8px 20px;
  border-radius: 100px;

  color: #000;

  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  height: 28px;
  min-height: 28px;
  max-width: 141px;
  display: flex;
  gap: 11px;
  align-items: center;
}

.desktopReviewsTabNav {
  background: #1e1f20;

  &>nav {
    max-width: 1347px;
    margin: 0 auto;
    width: 100%;
    gap: 33px;

    @media (max-width: 1347px) {
      max-width: 100vw;
      padding: 0 24px;
      gap: 28px;
    }

    @media (max-width: 767px) {
      &>div {
        margin: 0;
      }
    }

    & button {
      padding: 15px 0 7px;
    }

    & h3 {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      /* 120% */
      margin: 0;
    }
  }
}

.listingDetails {
  max-width: 1347px;
  margin: 74px auto 0;
  padding-bottom: 86px;

  @media (max-width: 1347px) {
    padding: 0 24px 86px;
    max-width: 100vw;
    margin: 30px auto 0;
  }
}

.titleWithBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 72px;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    margin-bottom: 54px;
  }

  @media (max-width: 767px) {
    margin-bottom: 54px;
    justify-content: center;

    & h4 {
      font-size: 33px;
      line-height: 33px;
    }

    &>a {
      display: none;
    }
  }

  & h4 {
    margin: 0;
    padding: 0;
  }
}

.viewAllBtn {
  color: #fff;
  text-align: right;
  font-family: 'Sofia Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 225% */
  letter-spacing: -0.348px;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: #ff0000;
  }
}

.sliderContent {
  border-top: 1px solid #fff;
  padding-top: 62px;
  margin-top: 64px;
  overflow: hidden;
  max-width: 100vw;

  & :global(.slick-track) {
    display: flex !important;
  }
}

.sectionReviews {
  border-top: 1px solid #fff;
  padding-top: 62px;
  margin-top: 64px;
}

.prevArrow {
  border: 0;
  position: absolute;
  left: -20px;
  top: 35%;
  z-index: 3;
  cursor: pointer;
  display: none;

  & svg {
    transform: rotate(180deg);
  }

  &:disabled {
    display: none;
  }
}

.nextArrow {
  border: 0;
  position: absolute;
  right: 0;
  top: 35%;
  z-index: 3;
  cursor: pointer;

  &:disabled {
    display: none;
  }
}

.listingCardsScroll {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 280px;
    background: linear-gradient(91deg, rgba(0, 0, 0, 0) 0.59%, #000 39.06%);
    position: absolute;
    right: -140px;
    top: 0;
    bottom: 0;
    height: 100%;
  }
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 35px;
  padding: 0;

  @media (max-width: 767px) {
    margin: 0 0 25px;
    justify-content: center;
    align-items: flex-start;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    /* 116.667% */
    letter-spacing: -1.072px;
  }
}

.ratingFilters {
  display: flex;
  align-items: center;
  gap: 11px;
  flex-wrap: wrap;
  margin: 35px 0 47px;

  @media (max-width: 767px) {
    margin: 25px 0 37px;
  }

  & .recent {
    background: #9d9d9d;
  }

  &>span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 17px;
    min-width: 108px;
    height: 28px;
    border-radius: 100px;
    border: 1px solid var(--Mid-Grey, #9d9d9d);
    background: #343434;

    @media (max-width: 767px) {
      min-width: 96px;
      padding: 4px 12px;
    }
  }
}

.castSection {
  max-width: 400px;
  width: 100%;
  margin-top: 70px;

  @media (max-width: 767px) {
    margin-top: 43px;
  }
}

.authorSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.castItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.castItemsWrapper {
  margin-top: 28px;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  /* 211.765% */
  letter-spacing: -0.348px;

  &.castItemsWrapper2 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;

    & .castItem {
      flex-basis: calc(50% - 10px);

      @media (max-width: 767px) {
        flex-basis: 100%;
      }

      & span {
        &:first-child {
          flex-basis: 40%;

          @media (max-width: 767px) {
            flex-basis: 50%;
          }
        }

        &:last-child {
          flex-basis: 60%;

          @media (max-width: 767px) {
            flex-basis: 50%;
          }
        }
      }
    }
  }

  & .castItem {
    &>span {
      &:first-child {
        color: var(--Mid-Grey, #9d9d9d);
      }

      &:last-child {
        color: #fff;
      }
    }
  }
}

.castCrewTitle {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  /* 180% */
  letter-spacing: -0.348px;
}

.castSection2 {
  margin-top: 82px;
}

.castSectionItem {
  margin-bottom: 40px;
}

.crossIcon {
  margin-left: 98%;
}
@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  margin-top: 43px;
  /* padding-bottom: 77px;
  margin-bottom: 62px;
  border-bottom: 1px solid #fff; */
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 35px;
  }
}

.content {
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
  width: 100%;
  flex-direction: column;
  align-items: center;
  }
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
  width: 182px;
  height: 182px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.66), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: transparent;
  background-image: none;

  cursor: pointer;
  @media (max-width: 767px) {
    margin-right: 16px;
    width: 182px;
    height: 182px;
    margin: 0;
  }
}

.userName {
  color: #fff;
  font-family: 'Sofia Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 488.889% */
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 17px;
  }
}

.displayName {
  font-size: 33px;
  font-style: normal;
  font-weight: 900;
  line-height: 33px; /* 266.667% */
  text-decoration-line: underline;
  @media (max-width: 767px) {
    text-align: center;
  }
}

.info {
  flex: 1;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  width: 100%;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.348px;
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;
  max-width: 591px;
  margin-top: 24px;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
  letter-spacing: -0.348px;
  @media (--viewportMedium) {
    display: inline-block;

    &::before {
      content: ' ';
    }
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.348px;
  text-decoration-line: underline;
  text-transform: uppercase;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
  }
}

.links {
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  composes: p from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin: 0;
  }
}
